'use strict'

###*
 # @ngdoc service
 # @name lpaMapUtils.factory:LpaMapUtils

 # @description

###
angular
  .module 'lpaMapUtils'
  .factory 'LpaMapUtils', [
    '$log'
    '$state'
    '$filter'
    'MundoMap'
    'moment'
    'RestUtils'
    'UnitStyle'
    'Tasks'
    'DisplayLabel'
    '$interpolate'
    '$compile'
    'FocusManager'
    'IncidentsManager'
    '$mdDialog'
    'mundoConfiguration'
    (
      $log,
      $state,
      $filter,
      MundoMap,
      moment,
      RestUtils,
      UnitStyle,
      Tasks,
      DisplayLabel,
      $interpolate,
      $compile,
      FocusManager
      IncidentsManager
      $mdDialog
      mundoConfiguration
    ) ->
      LpaMapUtilsBase = {}

      # ----------------------------------------
      # UNITS AND STATUSES
      # ----------------------------------------

      # Add a new status to the map, duh
      #
      # @param [Object] status - Status from the database
      # @param [Object] features - All features currently on the map
      # @param [Object] layer - Openlayers layer to add the feature to
      # @param [Object] statuses - List of statuses already on the map
      # @param [Object] [proposal] - When the status is actually a proposal, add some more stuff to the feature
      # @param [Boolean] [visible] - Wether the feature should be visible or not
      # @param [Object] [trailLayer] - Layer to add trails to

      LpaMapUtilsBase.addStatusToMap = (
        status,
        features,
        layer,
        statuses,
        proposal = null,
        visible = true,
        trailLayer = null
      ) ->
        promise = new Promise (resolve, reject) ->
          if status.location.point
            # Initial declaration and getting of some important variables
            unit = status.unit
            location = status.location
            status.type = 'status'
            asset = status.asset
            timestamp = status.timestamp.event

            # Parse the point to geometry
            geometry = MundoMap.getGeometryFromGeoJSON location.point

            # Parse stuff for searching
            tags = [unit.label]

            if (unit.dispatchUnit?) and (unit.dispatchUnit.label?)
              tags.push unit.dispatchUnit.label
              tags.push unit.dispatchUnit.label.replace('BRABO', 'B')

            if asset.data
              if asset.data.al_number
                tags.push asset.data.al_number

              if asset.data.roof_number
                tags.push asset.data.roof_number

            # Check if there is not yet a feature for this unit
            feature = features[unit.id]
            trail = if feature? then feature.get '_trail' else null

            activeDisplayLabel = DisplayLabel.getActiveKey()

            # Create a new feature
            if not feature
              feature = new ol.Feature
                geometry: geometry
                name: unit.label or unit.id

              trail = new ol.Feature
                geometry: new ol.geom.LineString([])

              feature.set '_markerUrl', MundoMap.getMarkerPath(status.unit.id)
              feature.set '_trail', trail

              if proposal
                # time = proposal.route.paths[0].time / 60000
                # estimation = moment.duration(time, 'minutes').minutes()
                # estimation = $filter('amDurationFormat')(time, 'minutes')
                time = proposal.route.paths[0].time
                estimation = $filter('humanizeDuration')(time, { round: true, units: ['m'] })
                feature.set 'estimation', estimation
                feature.set '_proposal', proposal

              features[unit.id] = feature

              if visible
                MundoMap.getLayerSource(layer).addFeature(feature)

                if trailLayer
                  MundoMap.getLayerSource(trailLayer).addFeature(trail)
            else
              # OVERRIDE EXISTING FEATURE
              if status.timeToIncident
                time = status.timeToIncident
                estimation = $filter('humanizeDuration')(time, { round: true, units: ['m'] })
                feature.set 'estimation', estimation
                feature.set '_proposal', null

            feature.setGeometry geometry
            feature.set '_status', status
            feature.set '_tags', tags

            trailGeometry = trail.getGeometry()
            coordinate = ol.proj.transform(location.point.coordinates, 'EPSG:4326', 'EPSG:3857')
            trailGeometry.appendCoordinate coordinate

            # Only keep the last X points in the trail
            limit = 25
            coordinates = trailGeometry.getCoordinates()
            if coordinates.length > limit
              coordinates.splice(0, coordinates.length - limit)
              trailGeometry.setCoordinates(coordinates)

            # trail.getGeometry().appendCoordinate coordinate
            # trail.set '_cachedStyle', null

            if (unit.dispatchUnit?) and (unit.dispatchUnit.label?)
              feature.set 'brabo', unit.dispatchUnit.label
              feature.set 'inactive', false
            else
              feature.set 'brabo', 'INACTIEF'
              feature.set 'inactive', true

            if asset.data? and asset.data.length and (asset.data[0].al_number)?
              feature.set 'al', asset.data[0].al_number
            else
              if asset.label?
                feature.set 'al', asset.label
              else
                if unit.label?
                  feature.set 'al', unit.label
                else
                  feature.setProperties
                    al: "- NR -"

            feature.set 'label', ((feature.get(activeDisplayLabel).replace 'BRABO', 'B').replace 'INACTIEF', 'INACT')
            feature.set '_cachedStyle', null
            statuses[unit.id] = status

            return resolve
          else
            return resolve
        return promise

      # Sets the style of the vehicles and how they are shown
      #
      # @param [Object] layer - The layer to apply the style to
      LpaMapUtilsBase.setVehicleLayerStyle = (layer) ->
        layer.setStyle (feature) ->
          return UnitStyle.style feature

      LpaMapUtilsBase.addIncidentToMap = (incident, features, layer, incidents) ->
        customIncident = incident
        customIncident['type'] = 'incident'
        feature = features[customIncident.id]
        # unit = customIncident.unit
        customIncident['location'] = {
          "point": {
            "coordinates": [customIncident.locationLongitude, customIncident.locationLatitude],
            "type": "Point"
          }
        }
        geometry = MundoMap.getGeometryFromGeoJSON customIncident.location.point
        if not feature
          feature = feature = new ol.Feature
            geometry: geometry
            name: customIncident.externalId
          feature.setProperties
            _incident: customIncident
            _tags: [
              customIncident.locationDescription
              customIncident.externalId
            ]
          features[customIncident.id] = feature

          if layer?
            MundoMap.getLayerSource(layer).addFeature feature
        else
          feature.setGeometry geometry
          feature.set '_cachedStyle', null
          feature.set '_incident', customIncident

        if incidents?
          incidents[customIncident.id] = customIncident

      # Remove a feature from a layer and remove it from the object it is in
      LpaMapUtilsBase.removeIncidentFromMap = (incidentId, features, layer, incidents) ->
        LayerSource = MundoMap.getLayerSource layer
        feature = features[incidentId]
        if feature?
          try
            LayerSource.removeFeature feature
          catch error
            #
          delete incidents[incidentId]
          delete features[incidentId]

      LpaMapUtilsBase.addPOIOrderToMap = (poiOrder, features, layer, poiOrders) ->
        customPOIOrder = poiOrder
        customPOIOrder['type'] = 'poi_order'
        feature = features[customPOIOrder.id]
        # unit = customPOIOrder.unit
        customPOIOrder['location'] = {
          "point": {
            "coordinates": [customPOIOrder.lpaPoiOrder.locationLongitude, customPOIOrder.lpaPoiOrder.locationLatitude],
            "type": "Point"
          }
        }
        
        geometry = MundoMap.getGeometryFromGeoJSON customPOIOrder.location.point
        if not feature
          feature = feature = new ol.Feature
            geometry: geometry
            name: customPOIOrder.lpaPoiOrder.externalId
          feature.setProperties
            _poiOrder: customPOIOrder
            _tags: [
              customPOIOrder.lpaPoiOrder.locationDescription
              customPOIOrder.lpaPoiOrder.externalId
            ]
          features[customPOIOrder.id] = feature
          if layer?
            MundoMap.getLayerSource(layer).addFeature feature
        else
          feature.setGeometry geometry
          feature.set '_cachedStyle', null
          feature.set '_poiOrder', customPOIOrder
  
        if poiOrders?
          poiOrders[customPOIOrder.id] = customPOIOrder

      # Remove a feature from a layer and remove it from the object it is in
      LpaMapUtilsBase.removePOIOrderFromMap = (poiOrderId, features, layer, poiOrders) ->
        LayerSource = MundoMap.getLayerSource layer
        feature = features[poiOrderId]
        if feature?
          try
            LayerSource.removeFeature feature
          catch error
            #
          delete poiOrders[poiOrderId]
          delete features[poiOrderId]

      LpaMapUtilsBase.addRouteToMap = (route, existingFeature, layer, properties = {}) ->
        feature = existingFeature
        format = new ol.format.Polyline()

        geometry = format.readGeometry route.paths[0].points,
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:900913'

        # Gonna hang the time it takes to the feature so we can use it to style
        # it in the style function
        time = route.paths[0].time

        if not feature
          feature = new ol.Feature
            geometry: geometry
            time: time

          MundoMap.getLayerSource(layer).addFeature feature

        feature.setGeometry geometry
        feature.setProperties properties

        return feature

      LpaMapUtilsBase.addProposalToMap = (proposal, existingFeature, layer, container) ->
        feature = @addRouteToMap proposal.route, existingFeature, layer,
          _proposal: proposal
        container[proposal.unit.id] = feature

      LpaMapUtilsBase.setProposalVehicleLayerStyle = (layer) ->

        layer.setStyle (feature) ->
          return UnitStyle.style feature,
            isProposal: true

      LpaMapUtilsBase.setIncidentLayerStyle = (layer) ->

        layer.setStyle (feature) ->
          features = feature.get('features')
          style = null

          if features.length == 1
            feature = features[0]

            props = feature.getProperties()
            bgColor = '#f25959'
            if props._incident.incidentPriority.color?
              bgColor = props._incident.incidentPriority.color
              bgColor = if !bgColor.startsWith '#' then '#' + bgColor
            style = null
            textColor = '#FFFFFF'

            style = feature.get '_cachedStyle'

            if not style
              style = [
                new ol.style.Style
                  image: new ol.style.Icon
                    #/** @type {olx.style.IconOptions} */
                    anchor: [0.5, 1.0]
                    anchorXUnits: 'fraction'
                    anchorYUnits: 'fraction'
                    snapToPixel: true
                    opacity: 0.8
                    src: "/images/map-incident/incident-small.png"
                    color: bgColor
                  text: new ol.style.Text
                    #PA05582184
                    text: feature.get('name').substr(-4)
                    scale: 1.3
                    offsetY: -28
                    offsetX: 0
                    fill: new ol.style.Fill
                      color: textColor
              ]

            feature.set '_cachedStyle', style

          else
            style = feature.get '_cachedStyle'
            features.sort (a, b) ->
              a.get('_incident').incidentPriority.weight - b.get('_incident').incidentPriority.weight
            mainFeature = features[0]

            if not style
              props = mainFeature.getProperties()
              bgColor = '#f25959'
              if props._incident.incidentPriority.color?
                bgColor = props._incident.incidentPriority.color
                bgColor = if !bgColor.startsWith '#' then '#' + bgColor
              style = null
              textColor = '#FFFFFF'

              style = [
                new ol.style.Style
                  image: new ol.style.Icon
                    #/** @type {olx.style.IconOptions} */
                    anchor: [0.5, 1.0]
                    anchorXUnits: 'fraction'
                    anchorYUnits: 'fraction'
                    snapToPixel: true
                    opacity: 0.8
                    src: "/images/map-incident/incident-small.png"
                    color: bgColor
                  text: new ol.style.Text
                    #PA05582184
                    text: mainFeature.get('name').substr(-4)
                    scale: 1.3
                    offsetY: -30
                    offsetX: 0
                    fill: new ol.style.Fill
                      color: textColor
              ,
                new ol.style.Style
                  text: new ol.style.Text
                    text: "+#{features.length - 1}"
                    scale: 1.2
                    offsetY: -17
                    offsetX: 0
                    fill: new ol.style.Fill
                      color: textColor
              ]

            feature.set '_cachedStyle', style

          return style

      LpaMapUtilsBase.setTrailsLayerStyle = (layer) ->

        layer.setStyle (feature) ->
          # Gets the time in milliseconds
          style = feature.get('_cachedStyle')

          opacity = 0.7

          # Set the possible colors
          color = [255, 255, 255, opacity]

          if not style
            style = [
              new ol.style.Style
                stroke: new ol.style.Stroke
                  color: [0, 0, 0, opacity]
                  width: 4
            ,
              new ol.style.Style
                stroke: new ol.style.Stroke
                  color: [21, 182, 231, opacity]
                  width: 2
              ]

          feature.set '_cachedStyle', style

          style

        true

      LpaMapUtilsBase.setRoutesLayerStyle = (layer) ->
        layer.setStyle (feature) ->
          style = feature.get('_cachedStyle')

          if not style
            proposal = feature.get('_proposal')
            highlighting = feature.get('_highlight')?
            highlighted = feature.get('_highlight') == true

            opacity = 0.7
            color = [255, 255, 255, opacity]
            zIndex = 10

            if proposal
              if highlighting
                if highlighted
                  opacity += 0.1
                  zIndex += 10
                else
                  opacity -= 0.5

              style = [
                new ol.style.Style
                  stroke: new ol.style.Stroke
                    color: [0, 0, 0, opacity]
                    width: 7
                  zIndex: zIndex
              ,
                new ol.style.Style
                  stroke: new ol.style.Stroke
                    color: [100, 255, 100, opacity + 0.2]
                    width: 4
                  zIndex: zIndex
              ]
            else
              style = [
                new ol.style.Style
                  stroke: new ol.style.Stroke
                    color: [50, 200, 50, opacity]
                    width: 7
              ,
                new ol.style.Style
                  stroke: new ol.style.Stroke
                    color: [120, 120, 255, opacity]
                    width: 4
              ]

          feature.set '_cachedStyle', style

          return style

        return true

      LpaMapUtilsBase.setPOIOrderLayerStyle = (layer) ->

        layer.setStyle (feature) ->
          features = feature.get('features')
          style = null

          if features.length == 1
            feature = features[0]

            props = feature.getProperties()
            bgColor = '#f25959'
            style = null
            textColor = '#FFFFFF'

            style = feature.get '_cachedStyle'

            if not style
              style = [
                new ol.style.Style
                  image: new ol.style.Icon
                    #/** @type {olx.style.IconOptions} */
                    anchor: [0.5, 1.0]
                    anchorXUnits: 'fraction'
                    anchorYUnits: 'fraction'
                    snapToPixel: true
                    opacity: 0.8
                    src: "/images/map-incident/incident-small.png"
                    color: bgColor
                  text: new ol.style.Text
                    #PA05582184
                    text: feature.get('name').substr(-4)
                    scale: 1.3
                    offsetY: -28
                    offsetX: 0
                    fill: new ol.style.Fill
                      color: textColor
              ]

            feature.set '_cachedStyle', style

          else
            style = feature.get '_cachedStyle'
            mainFeature = features[0]

            if not style
              props = mainFeature.getProperties()
              bgColor = '#f25959'
              style = null
              textColor = '#FFFFFF'

              style = [
                new ol.style.Style
                  image: new ol.style.Icon
                    #/** @type {olx.style.IconOptions} */
                    anchor: [0.5, 1.0]
                    anchorXUnits: 'fraction'
                    anchorYUnits: 'fraction'
                    snapToPixel: true
                    opacity: 0.8
                    src: "/images/map-incident/incident-small.png"
                    color: bgColor
                  text: new ol.style.Text
                    #PA05582184
                    text: mainFeature.get('name').substr(-4)
                    scale: 1.3
                    offsetY: -30
                    offsetX: 0
                    fill: new ol.style.Fill
                      color: textColor
              ,
                new ol.style.Style
                  text: new ol.style.Text
                    text: "+#{features.length - 1}"
                    scale: 1.2
                    offsetY: -17
                    offsetX: 0
                    fill: new ol.style.Fill
                      color: textColor
              ]

            feature.set '_cachedStyle', style

          return style

      LpaMapUtilsBase.flushCaches = (features) ->
        angular.forEach features, (feature) ->
          feature.set '_cachedStyle', null

      # ----------------------------------------
      # OPENING NEW WINDOWS
      # ----------------------------------------

      LpaMapUtilsBase.openIncidentWindow = (incident) ->
        url = $state.href('incidentTracking', {incident_id: incident['id']}, {absolute: true})
        openedWindow = window.open url, 'uniqueName' + incident['id'], 'width=1100,height=800', false
        # This return is added because else we have a console error
        # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
        return true

      LpaMapUtilsBase.openFastTrackVehicleWindow = (status) ->
        url = $state.href('fastTracking', {unit_id: status.unit.id}, {absolute: true})
        openedWindow = window.open url, 'uniqueName' + status.unit.id, 'width=1100,height=800', false
        # This return is added because else we have a console error
        # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
        return true
      
      LpaMapUtilsBase.openPOIOrderWindow = (poiOrder) ->
        url = $state.href('poiOrderTracking', {poi_order_id: poiOrder['id']}, {absolute: true})
        openedWindow = window.open url, 'uniqueName' + poiOrder['id'], 'width=1100,height=800', false
        return true

      # ----------------------------------------
      # TOOLTIP POPUPS ON MARKERS
      # ----------------------------------------

      LpaMapUtilsBase.addVehiclePopup = ($scope, feature, overlay) ->
        status = feature.get '_status'

        if status?
          res = """
            <div id="lpa-popup" ng-include="::'lpa/lpa-dispatching/views/vehicle-popup.tpl.html'"></div>
            """

          overlay.show feature.getGeometry().getCoordinates(), res
          pp = angular.element(document.querySelector("#lpa-popup"))

          scope = $scope
          scope._popup =
            status: status
          $compile(pp)(scope)
          $scope.$digest()

      LpaMapUtilsBase.addVehicleClusterPopup = ($scope, feature, overlay) ->
        featureProps = feature.getProperties()
        features = featureProps.features

        if features.length
          statuses = (x.get('_status') for x in features)

          res = """
            <div id="lpa-popup" ng-include="::'lpa/lpa-dispatching/views/vehicle-cluster-popup.tpl.html'"></div>
            """

          overlay.show feature.getGeometry().getCoordinates(), res
          pp = angular.element(document.querySelector("#lpa-popup"))

          scope = $scope
          scope._popup =
            statuses: statuses,
            openFastTrackVehicleWindow: @openFastTrackVehicleWindow
          $compile(pp)(scope)
          $scope.$digest()

      LpaMapUtilsBase.addIncidentPopup = ($scope, feature, overlay) ->
        incident = feature.get '_incident'

        if incident?
          if not incident.tasks?
              RestUtils.getFullList Tasks,
                "filter[]": [
                  "incident.id,#{incident.id}"
                  "closedAt,NULL"
                ]
              .then (tasks) ->
                incident.tasks = tasks

          res = """
            <div id="lpa-popup" ng-include="::'lpa/lpa-dispatching/views/incident-popup.tpl.html'"></div>
            """

          overlay.show feature.getGeometry().getCoordinates(), res
          pp = angular.element(document.querySelector("#lpa-popup"))
          $log.debug "LPAMapUtils: Opening popup for incident '#{incident.id}'"
          scope = $scope
          scope._popup =
            incident: incident
            dispatchFeatures: mundoConfiguration.dispatchFeatures
            closeIncident: () ->
              IncidentsManager.closeIntercadIncident incident
              .then () ->
                overlay.hide()
            editIncident: () ->
              IncidentsManager.editIncident incident
              .then () ->
                overlay.hide()
          $compile(pp)(scope)
          $scope.$digest()

      LpaMapUtilsBase.addIncidentClusterPopup = ($scope, feature, overlay) ->
        featureProps = feature.getProperties()
        features = featureProps.features

        if features.length
          incidents = (x.get('_incident') for x in features)
          incidents.sort (a, b) -> a.incidentPriority.weight - b.incidentPriority.weight

          res = """
            <div id="lpa-popup" ng-include="::'lpa/lpa-dispatching/views/incident-cluster-popup.tpl.html'"></div>
            """

          overlay.show feature.getGeometry().getCoordinates(), res
          pp = angular.element(document.querySelector("#lpa-popup"))

          scope = $scope
          scope._popup =
            incidents: incidents,
            openIncidentWindow: @openIncidentWindow
          $compile(pp)(scope)
          $scope.$digest()

      LpaMapUtilsBase.addPOIOrderPopup = ($scope, feature, overlay) ->
        poiOrder = feature.get '_poiOrder'

        if poiOrder?
          res = """
            <div id="lpa-popup" ng-include="::'lpa/lpa-dispatching/views/poi-order-popup.tpl.html'"></div>
            """

          overlay.show feature.getGeometry().getCoordinates(), res
          pp = angular.element(document.querySelector("#lpa-popup"))
          $log.debug "LPAMapUtils: Opening popup for poi order '#{poiOrder.id}'"
          scope = $scope
          scope._popup =
            poiOrder: poiOrder
            closePOIOrder: () ->
              FocusManager.closePOIOrder poiOrder
              .then () ->
                overlay.hide()
            editPOIOrder: () ->
              FocusManager.editPOIOrder poiOrder
              .then () ->
                $mdDialog.hide()
                overlay.hide()
          $compile(pp)(scope)
          $scope.$digest()

      LpaMapUtilsBase.addPOIOrderClusterPopup = ($scope, feature, overlay) ->
        featureProps = feature.getProperties()
        features = featureProps.features

        if features.length
          poiOrders = (x.get('_poiOrder') for x in features)

          res = """
            <div id="lpa-popup" ng-include="::'lpa/lpa-dispatching/views/poi-order-cluster-popup.tpl.html'"></div>
            """

          overlay.show feature.getGeometry().getCoordinates(), res
          pp = angular.element(document.querySelector("#lpa-popup"))

          scope = $scope
          scope._popup =
            poiOrders: poiOrders,
            openPOIOrderWindow: @openPOIOrderWindow
          $compile(pp)(scope)
          $scope.$digest()

      LpaMapUtilsBase.addCameraPopup = ($scope, feature, overlay) ->
        camera = feature
        $log.debug camera

        res = """
          <div id="lpa-camera-popup" ng-include="::'lpa/lpa-dispatching/views/camera-popup.tpl.html'"></div>
          """

        overlay.show feature.getGeometry().getCoordinates(), res
        pp = angular.element(document.querySelector("#lpa-camera-popup"))
        scope = $scope
        scope._popup =
          camera:
            name: camera.get('camera_nam')
            id: camera.get('OBJECTID')
            number: camera.get('camera_num')
        $compile(pp)(scope)
        $scope.$digest()

      LpaMapUtilsBase.showContextMenu = (e, map, scope, popupOverlay) ->
        e.preventDefault()
        hasFeatures = false

        map.forEachFeatureAtPixel(map.getEventPixel(e), (feature, layer) =>
          hasFeatures = true
          layerProps = layer.getProperties()
          featureProps = feature.getProperties()

          if layerProps._layerId is "markers"
            features = featureProps.features

            if features.length == 1
              this.addVehiclePopup scope, features[0], popupOverlay
            else
              this.addVehicleClusterPopup scope, feature, popupOverlay

          else if layerProps._layerId is "incidents"
            features = featureProps.features

            if features.length == 1
              this.addIncidentPopup scope, features[0], popupOverlay
            else
              this.addIncidentClusterPopup scope, feature, popupOverlay

          else if layerProps._layerId is "poiOrders"
            features = featureProps.features

            if features.length == 1
              this.addPOIOrderPopup scope, features[0], popupOverlay
            else
              this.addPOIOrderClusterPopup scope, feature, popupOverlay

          else if layerProps._layerId is "anpr"
            this.addCameraPopup scope, feature, popupOverlay
        )

        if not hasFeatures
          popupOverlay.hide()

      LpaMapUtilsBase
    ]
